import image1 from '../assets/01-min-min.png';
import image2 from '../assets/02-min-min.png';
import image3 from '../assets/03-min-min.png';
import image4 from '../assets/04-min-min.png';
import image5 from '../assets/05-min-min.png';
import image6 from '../assets/06-min-min.png';
import image7 from '../assets/07-min-min.png';
import image8 from '../assets/08-min-min.png';
import image9 from '../assets/09-min-min.png';
import image10 from '../assets/10-min-min.png';

import Slider from "react-slick";



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent" }}
        onClick={onClick}
      />
    );
  }

const ABC=()=>{


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                dots: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            }
          ]
      }


    return(
        <section id="ABC">
            <div className="container">
                


                    
                    <div>
                        <Slider style={{ width: "100%" }} className="sldier-carousel" {...settings}>
                            {/* slide 1 */}
                        <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image1} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Stormbringer</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A fierce warrior with the ability to control the elements, using lightning, 
                                                wind, and rain to devastate her opponents.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>


                            {/* slide 2 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image2} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Frostfire</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A hybrid fighter with the unique ability to harness both ice and fire, 
                                                using them to freeze and burn his enemies
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>


                                                        {/* slide 3 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image3} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Gravity Master</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A powerful psychic who can read the thoughts of her opponents and use them against them, 
                                                as well as telekinetically manipulate objects.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 4 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image4} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Shadowstrike</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A stealthy assassin who can move through the shadows, using her speed and agility 
                                                to deliver deadly blows from the shadows.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 5 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image5} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Toxic Avenger</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A mutated brawler with toxic powers, able to poison his opponents with a single touch or 
                                                blast them with toxic energy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 6 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image6} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Telepath</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A formidable fighter with the ability to manipulate gravity, using it to 
                                                crush his enemies with powerful gravitational waves.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 7 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image7} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Time Lord</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A mysterious fighter with the power to manipulate time, 
                                                able to freeze, slow down, or even reverse the flow of time for his opponents.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 8 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image8} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Psycho Killer</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A mentally unstable brawler with the ability to project his own 
                                                violent thoughts and emotions onto his opponents, driving them to madness.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 9 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image9} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Robo Riot</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A robotic fighter with advanced artificial intelligence and a 
                                                variety of powerful weapons and abilities at its disposal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                                                        {/* slide 10 */}
                            <div className="inner-abc-container">
                                <div className="left-ABC">
                                <div className="left-ABC-image">
                                    <img src={image10} alt='nft image' />
                                </div>
                                </div>
                                <div className="right-ABC">
                                    <div className="right-ABC-text">
                                        <div className="right-container-text-heading-ABC">
                                            <h2>Elemental Fury</h2>
                                        </div>
                                        <div className="right-container-text-text-ABC">
                                            
                                            <p>
                                                A powerful being able to harness the power of the elements, 
                                                using fire, water, earth, and air to devastate their opponents.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>


                        </Slider>
                    </div>

                {/*    
                <div className="left-ABC">
                    <div className="left-ABC-image">
                        <img src={image1} alt='nft image' />
                    </div>
                    </div>
                    <div className="right-ABC">
                        <div className="right-ABC-text">
                            <div className="right-container-text-heading-ABC">
                                <h2>CHARACTERS</h2>
                            </div>
                            <div className="right-container-text-text-ABC">
                                <span><p>1- BENEFITS FOR OWNERS</p></span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    Perspiciatis id enim hic sequi commodi iusto totam dolorem, 
                                    ut magnam facilis ab, accusantium distinctio mollitia, 
                                    sint odio a minus dolores dolorum.
                                </p>
                                <span><p>2- COLLECTIBLES</p></span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    Perspiciatis id enim hic sequi commodi iusto totam dolorem, 
                                    ut magnam facilis ab, accusantium distinctio mollitia, 
                                    sint odio a minus dolores dolorum.
                                </p>
                                <span><p>3- SPECIAL CHARACTERS</p></span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                    Perspiciatis id enim hic sequi commodi iusto totam dolorem, 
                                    ut magnam facilis ab, accusantium distinctio mollitia, 
                                    sint odio a minus dolores dolorum.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{clear: "both"}}></div>
                    */}
            </div>
        </section>
    );
}

export default ABC;