import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSnapchat,
    faTwitter,
    faDiscord,
    faLinkedin,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

const Footer=()=>{
    return(
        <section id="footer">
            <div className="container">
                <div className="icon-container">
                    <div className="social-container">
                        <a target="_blank" rel="noopener noreferrer" className="youtube social">
                            <FontAwesomeIcon icon={faTwitter} size="2x" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" className="facebook social">
                            <FontAwesomeIcon icon={faDiscord} size="2x" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" className="linkedin social">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" className="instagram social">
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" className="instagram social">
                            <FontAwesomeIcon icon={faSnapchat} size="2x" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;