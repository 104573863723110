import ABC from "./ABC";
import Ecosystem from "./Ecosystem";
import Footer from "./Footer";
import GameFighting from "./GameFighting";
import GameMode from "./GameMode";
import Header from "./Header";
import Roadmap from "./Roadmap";
import Teams from "./Teams";
import Tokenomics from "./Tokenomics";
import TokenUtility from "./TokenUtility";

const Home=()=>{
    return(
        <>
        <Header />
        <Ecosystem />
        <TokenUtility />
        <Tokenomics />
        <GameFighting />
        <GameMode />
        <ABC /> 
        <Roadmap />
        <Teams />
        <Footer />
        </>
    );
}

export default Home;