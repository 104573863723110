/*import image1 from '../assets/road.png';
import image2 from '../assets/phase1.png';
import image3 from '../assets/phase2.png';
import image4 from '../assets/phase3.png';*/

import image1 from '../assets/road.png';

const Roadmap=()=>{
    return(
        <section id="Roadmap">
            <div className="container">
                <div className="inner-roadmapconatiner">
                    <div className='roadmap-heading'>
                        <h2>ROADMAP</h2>
                    </div>


                <div className="roadmap-sec p-tb white-sec c-l" id="roadmap">
                <div className="circle-ripple"></div>
                <div className="container">
                    <div className="sub-txt text-center body2">
                    <div className="whitemoon">
                        </div>
                    </div>
                    <div className="vertical-roadmap">
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div classname="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- Website Design and Development <br/> - Define project scope and objectives <br/>- Conduct research <br/>- Create a sitemap and wireframe  <br/>- Design and development <br/>- Test and launch <br/>- Maintenance and updates </p>
                                    </div>
                                </div>
                                <div className="roadmap-day">
                                    <span>Phase 1</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- tokenomics and whitepaper <br/> - Define tokenomics <br/>- Create a whitepaper  <br/>- Token sale <br/>- Token integration </p>
                                    </div>
                                </div>
                                <div className="roadmap-day">
                                    <span>Phase 2</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- Game demo <br/> - First character and its move-sets are fully implemented <br/>- Initial Demo UI flow implemented <br/>- The demo of final game's content and features </p>
                                    </div>
                                    </div>
                                <div className="roadmap-day">
                                    <span>Phase 3</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- NFT characters unique design <br/> - Create a set of unique, NFT characters for the game <br/>- The team will focus on creating a diverse set of characters  <br/>- The characters will be stored on a blockchain  <br/>- Players will be able to buy, sell, and trade these characters</p>
                                    </div>
                                    </div>
                                <div className="roadmap-day">
                                    <span>Phase 4</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- Integration of NFTs into game <br/> - Integrate the NFT characters and marketplace <br/>- The NFT characters will be fully functional within the game <br/>- Release the NFT integration to the public  </p>
                                    </div>
                                    </div>
                                <div className="roadmap-day">
                                    <span>Phase 5</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- Token creation and distribution via presale and airdrop <br/> - Create a native token for the game used for in-game purchases and trading NFT characters <br/>- Plan and execute a token presale <br/>- Set up and test smart contracts  <br/>- Release the token sale and airdrop to the public </p>
                                    </div>
                                    </div>
                                <div className="roadmap-day">
                                    <span>Phase 6</span>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item">
                            <div className="roadmap-text">
                                <div className="roadmap-item-text">
                                    
                                    <div className="whitemoon">
                                    <p className="lead">- complete game development and connection with Arbitrum Network (Xmetaco Token) <br/> - The final stages of development for the game <br/>- Integrate the Arbitrum Network (Xmetaco Token) <br/>- Set up and test smart contracts  <br/>- Integrate a wallet feature into the game</p>
                                    </div>
                                    </div>
                                <div className="roadmap-day">
                                    <span>Phase 7</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                    {/*
                    <div className='roadmap-content'>

                        <div className='roadmap-text'>
                            <p>webdsite design and development</p>
                            <p>tokenomics and whitepaper</p>
                            <p>game demo</p>
                            <p>nft characters unique design</p>
                            <p>integration of nfts into game</p>
                            <p>token creation and distributuon via presale and airdrop</p>
                            <p>complete game development and connection with Arbitarium token</p>
                        </div>

                        <div className="roadmap-image">
                            <img src={image1} alt='road'/>
                        </div>
                    </div>
                    */}


                    {/*
                    <div className="roadmapbg-image">
                        <img src={image1} alt='roadmap' />
                    </div>

                    <div className='roadmap-content'>
                        <div className='roadmap-content-inline'>
                            <div className='roadmap-content-inline-text'>
                                <p className='text1'>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                </p>
                            </div>
                            <div className='roadmap-content-inline-image' id='phase1'>
                                <img src={image2} alt='phase1'/>
                            </div>
                        </div>

                        <div className='roadmap-content-inline'>
                            <div className='roadmap-content-inline-image' id='phase2'>
                                <img src={image3} alt='phase1'/>
                            </div>
                            <div className='roadmap-content-inline-text'>
                                <p className='text2'>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                </p>
                            </div>
                        </div>

                        <div className='roadmap-content-inline'>
                            <div className='roadmap-content-inline-text'>
                                <p className='text3'>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                                </p>
                            </div>
                            <div className='roadmap-content-inline-image' id='phase3'>
                                <img src={image4} alt='phase1'/>
                            </div>
                        </div>
                    </div>
                    */}

                    <div className='roadmap-btn'>
                        <div className='roadmap-centerbtn'>
                            <a href="#"><p>BUY NFT</p></a>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    );
}

export default Roadmap;