import image1 from "../assets/nftimage.png";
import logo from '../assets/bannerlogo.png';

const GameFighting=()=>{
    return(
        <section id="GameFighting">
            <div className="container">
                <div className="inner-container">
                    <div className="left-GameFighting">
                        <div className="left-container-image">
                            <img src={image1} alt='nft image' />
                        </div>
                    </div>
                    <div className="right-GameFighting">
                        <div className="right-container-text">
                            <div className="right-container-text-heading">
                                {/*<img src={logo} alt='logo' />*/}
                                <h2>XMETACO</h2>
                                <h2>GAME</h2>
                            </div>
                            <div className="right-container-text-text">
                                <p>
                                XmetaCo is an exciting new fighting game that brings together a diverse cast of characters, each with their own unique abilities and playstyle. Set in a futuristic world where martial arts and advanced technology collide, XmetaCo pits players against each other in fast-paced, action-packed matches. <br/>
                                The gameplay is easy to pick up, but difficult to master, making it accessible to players of all skill levels. With a variety of game modes, including 1v1 matches (PvP leagues), tournament play, and team battles, there's always something new to try. <br/>
                                In addition to the core gameplay, XmetaCo also features a deep customization system, allowing players to customize their characters with a variety of skins, equipment, and other cosmetic items. <br/>
                                So if you're looking to take your fighting skills to the next level, look no further than XmetaCo! Join us in the virtual ring and become a champion! <br/>
                                </p>
                            </div>
                            {/*
                            <div className="gamefighting-btn">
                                <div className="btn">
                                    <a href="#"><p>PLAY (DEMO)</p></a>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                    <div style={{clear: "both"}}></div>
                </div>
            </div>
        </section>
    );
}

export default GameFighting;