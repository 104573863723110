import image1 from '../assets/pvpleague.png';
import image2 from '../assets/tournaments.png';
import image3 from '../assets/majorlogo.png';

const GameMode=()=>{
    return(
        <section id="GameMode">
            <div className="container">
                <div className="inner-container">
                    <div className="GameMode-heading">
                        <h2>GAME MODES</h2>
                    </div>

                    <div className="lower-container-inline">
                        <div className="inline-images">
                            <img src={image1} alt='PVP' />
                            <div className='inline-images-text'>
                                <h3>PVP LEAGUE</h3>
                                <p>
                                    In the PvP (Player vs Player) league mode, players compete against each other in 1v1 matches, 
                                    earning points based on their performance. These points are then used to determine 
                                    their standing within the league, with the top players in each league earning rewards and 
                                    prestige. This mode is perfect for players who want to test their skills against other players 
                                    and earn recognition for their abilities.
                                </p>
                            </div>
                        </div>
                        <div className="inline-images">
                            <img src={image2} alt='tournaments' />
                            <div className='inline-images-text'>
                                <h3>TOURNAMENTS</h3>
                                <p>
                                    Tournaments are organized events where players compete against each other in a bracket-style format. 
                                    Players are typically grouped into pools and then compete in a single elimination format. 
                                    Tournaments often have prize pools for the top finishers and can be held online or offline. 
                                    This mode is designed for players who want to experience a more competitive and structured setting 
                                    and also have a chance to earn prizes.
                                </p>
                            </div>
                        </div>
                        <div className="inline-images">
                            <img src={image3} alt='majors'/>
                            <div className='inline-images-text'>
                                <h3>MAJORS</h3>
                                <p>
                                    The Major mode is the most prestigious and high-profile event, usually held on a larger scale 
                                    than Tournaments, it is usually sponsored by game companies, open for professional and elite 
                                    players. In this mode, players compete against the best of the best for the grand prize and 
                                    recognition as the best in the world. The prize pool is often significant, attracting professional 
                                    players and sponsors. This mode is perfect for players who want to test their skills against the best 
                                    of the best and make a name for themselves in the competitive scene.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GameMode;