/*import image1 from '../assets/teamcircle.png';*/
import image2 from '../assets/mobeen.png';
import image3 from '../assets/jd.png';
import image4 from '../assets/hammad.png';
import image5 from '../assets/saira.png';

const Teams=()=>{
    return(
            <section id="Team">
            <div className="container">
                <div className="inner-teamcontainer">
                    <div className="team-heading">
                        <h2>TEAM</h2>
                    </div>

                    <div className="team-images">
                        <div className="inline-team-images">
                            <img src={image2} alt='team' />
                            <h3>MOBEEN</h3>
                            <p>LEAD UTILITY DEVELOPER</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image3} alt='team' />
                            <h3>JD</h3>
                            <p>CONTRACT DEVELOPER</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image4} alt='team' />
                            <h3>HAMMAD</h3>
                            <p>WEB DEVELOPER</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image5} alt='team' />
                            <h3>SAIRA</h3>
                            <p>GRAPHIC DESIGNER</p>
                        </div>
                    </div>

{/*
                    <div className="team-images">
                        <div className="inline-team-images">
                            <img src={image1} alt='team' />
                            <h3>XYZ</h3>
                            <p>Designation</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image1} alt='team' />
                            <h3>XYZ</h3>
                            <p>Designation</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image1} alt='team' />
                            <h3>XYZ</h3>
                            <p>Designation</p>
                        </div>
                        <div className="inline-team-images">
                            <img src={image1} alt='team' />
                            <h3>XYZ</h3>
                            <p>Designation</p>
                        </div>
                    </div>
*/}
                </div>
            </div>
    </section>
    );
}

export default Teams;