/*import image1 from '../assets/tokenomicsbar.png';*/
import image1 from '../assets/tokenomicspiechart.png';

import image2 from '../assets/BUTTON1.png';
import image3 from '../assets/BUTTON2.png';
import image4 from '../assets/BUTTON3.png';
import image5 from '../assets/BUTTON4.png';
import image6 from '../assets/BUTTON5.png';
import image7 from '../assets/BUTTON6.png';
import image8 from '../assets/BUTTON7.png';
import image9 from '../assets/BUTTON8.png';
import image10 from '../assets/BUTTON9.png';
import image11 from '../assets/BUTTON10.png';

const Tokenomics=()=>{
    return(
        <section id="tokenomics">
            <div className="container">
                <div className="inner-tokenomics">
                    <div className="tokenomics-heading">
                        <h2>TOKENOMICS</h2>
                    </div>

                    <div className="lower-tokenomics-container">
                        <div className="left-tokenomics">
                            <div className="left-tokenomics-image">
                                <div className='left-img-tokenomics'>
                                    <img src={image1} alt='chart'/>
                                </div>
                            </div>
                        </div>
                        <div className="right-tokenomics">
                            <div className='right-tokenomics-tax-container'>
                                <div className="tax-container-left">
                                    <img src={image2} alt='tax'/>
                                    <h4>35.5%</h4>
                                    <div className='tax-container-para'>
                                        <p>Presale</p>
                                    </div>
                                </div>
                                <div className="tax-container-right">
                                    <img src={image3} alt='tax'/>
                                    <h4>12.5%</h4>
                                    <div className='tax-container-para tax-container-para-2'>
                                        <p>NFT-Mint & Staking reward</p>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                            <div className='right-tokenomics-tax-container'>
                                <div className="tax-container-left">
                                    <img src={image4} alt='tax'/>
                                    <h4>15%</h4>
                                    <div className='tax-container-para'>
                                        <p>GAME REWARD</p>
                                    </div>
                                </div>
                                <div className="tax-container-right">
                                    <img src={image11} alt='tax'/>
                                    <h4>10%</h4>
                                    <div className='tax-container-para'>
                                        <p>burn event over 1 year</p>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                            <div className='right-tokenomics-tax-container'>
                                <div className="tax-container-left">
                                    <img src={image6} alt='tax'/>
                                    <h4>5%</h4>
                                    <div className='tax-container-para tax-container-para-5'>
                                        <p>MARKETING AND ADVERTISEMENT</p>
                                    </div>
                                </div>
                                <div className="tax-container-right">
                                    <img src={image7} alt='tax'/>
                                    <h4>5%</h4>
                                    <div className='tax-container-para'>
                                        <p>Staking XmetaCo Token</p>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                            <div className='right-tokenomics-tax-container'>
                                <div className="tax-container-left">
                                    <img src={image8} alt='tax'/>
                                    <h4>5%</h4>
                                    <div className='tax-container-para'>
                                        <p>Reserve OF CEX</p>
                                    </div>
                                </div>
                                <div className="tax-container-right">
                                    <img src={image10} alt='tax'/>
                                    <h4>12%</h4>
                                    <div className='tax-container-para tax-container-para-8'>
                                        <p>Team</p>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>

                            {/*
                            <div className='right-tokenomics-tax-container'>
                                <div className="tax-container-left">
                                    <img src={image10} alt='tax'/>
                                    <h4>3%</h4>
                                    <div className='tax-container-para'>
                                        <p>AIRDROP</p>
                                    </div>
                                </div>
                                <div className="tax-container-right">
                                    <img src={image11} alt='tax'/>
                                    <h4>10%</h4>
                                    <div className='tax-container-para'>
                                        <p>BURN EVENT OVER REWARDS</p>
                                    </div>
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                            /*}
                            {/*
                            <div className="tax-container">
                                <h3>TAX BUY</h3>
                                <img src={image1} alt='tax'/>
                                <h4>5%</h4>
                                <div className='tax-container-para'>
                                    <p>PARAGRAPHS</p>
                                    <p>WORDS</p>
                                    <p>BYTES</p>
                                    <p>LISTS</p>
                                </div>
                            </div>
                            <div className="tax-container">
                                <h3>SALE</h3>
                                <img src={image1} alt='tax'/>
                                <h4>10%</h4>
                                <div className='tax-container-para'>
                                    <p>PARAGRAPHS</p>
                                    <p>WORDS</p>
                                    <p>BYTES</p>
                                    <p>LISTS</p>
                                </div>
                            </div>
                            <div className="tax-container">
                                <h3>GAME BUY</h3>
                                <img src={image1} alt='tax'/>
                                <h4>5%</h4>
                                <div className='tax-container-para'>
                                    <p>PARAGRAPHS</p>
                                    <p>WORDS</p>
                                    <p>BYTES</p>
                                    <p>LISTS</p>
                                </div>
                            </div>
                            */}
                        </div>
                        <div style={{clear: "both"}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tokenomics;