import bar from '../assets/bannerline.png';

const Header=()=>{
    return(
        <section id="header">
            <div className="container">
                <div className="inner-container">
                    <div className="left-container">
                        <div className="header-heading">
                            <h1>XMETACO</h1>
                        </div>
                        <div className="header-bar">
                            <img src={bar} alt=''/>
                        </div>
                        <div className="header-text">
                            <p>Welcome to the Fighting Combat Game- XmetaCo! <br/>
                            
                                Our team has spent countless hours perfecting the gameplay, character balance, and overall experience, and we are dedicated to providing our players with the best possible gaming experience.
                                Join the fight today and see if you have what it takes to emerge victorious.
                            </p>
                        </div>
                        <div className="header-btn">
                            <a href="https://xmetaco.com/p2e" target="_blank"><h2>DEMO</h2></a>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="right-image">
                        </div>
                    </div>
                    <div style={{clear: "both"}}></div>
                </div>
            </div>
        </section>
    );
}

export default Header;