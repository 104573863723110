
const ContactUs=()=>{
    return(
        <section id="contact">
            <div className="container">
                <div className="contact-inner-container">
                    <div className="contact-heading">
                        <h2>Contact US</h2>
                    </div>

                    <div className="contactus-container">
                    <form
                        action=""
                        /*onSubmit={handleSubmit}*/
                        /*action="contact.php"*/
                        method="POST"
                        target="_blank"
                        className="contactForm"
                    
                        >

                        <div className="mb-3 pt-0">
                            <input id="input-field"
                            type="text"
                            placeholder="Your name"
                            name="name"
                            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                            required
                            />
                        </div>
                        <div className="mb-3 pt-0">
                            <input id="input-field"
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                            required
                            />
                        </div>
                        <div className="mb-3 pt-0">
                            <input id="input-field"
                            type="text"
                            placeholder="Subject"
                            name="subject"
                            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                            required
                            />
                        </div>
                        <div className="mb-3 pt-0">
                            <textarea id="text-field"
                            placeholder="Your message"
                            name="message"
                            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                            required
                            />
                        </div>
                        <div className="mb-3 pt-0">
                            <button id="contact-btn"
                            className="submit-btn bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                            >
                                SUBMIT
                            </button>


                        {/*<div className='row'>{result ? <Result/>: null}</div> */}

                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;