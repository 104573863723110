import image1 from '../assets/token.png';
import image2 from '../assets/gamelogo.png';
import image3 from '../assets/nftcardslogo.png';
import bar from '../assets/bannerline.png';

const Ecosystem=()=>{
    return(
        <section id="ecosystem">
            <div className="container">
                <div className="inner-ecosystem">
                    <div className="ecosystem-heading">
                        <h2>SYNERGY &nbsp; OF &nbsp; ECOSYSTEM</h2>
                    </div>

                    <div className="inline-container">
                        <div className="inline">
                            <img src={image1} alt='img1' />
                            <p>TOKEN</p>
                        </div>
                        <div className="inline">
                            <img src={image2} alt='img2' />
                            <p>GAME</p>
                        </div>
                        <div className="inline">
                            <img src={image3} alt='img3' />
                            <p>NFT</p>
                        </div>
                    </div>

                    <div className='bar-image'>
                        <img src={bar} alt='bar image'/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Ecosystem;