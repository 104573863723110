import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
/*import {LinkContainer} from 'react-router-bootstrap';
import { Outlet} from "react-router-dom";*/

import logo from '../assets/bannerlogo.png';

const Navigation=()=>{

    const navigate = useNavigate()

    function scroll(name){
        navigate('')
        var link = name
        console.log(link)
        setTimeout(()=>{
            if(link.includes('section1')){
                var element = document.getElementById('header')
                element.scrollIntoView()
            }
            else if(link.includes('section2')){
                var element = document.getElementById('ecosystem')
                element.scrollIntoView()
            }
            else if(link.includes('section3')){
                var element = document.getElementById('tokenomics')
                element.scrollIntoView()
            }
            else if(link.includes('section4')){
                var element = document.getElementById('GameFighting')
                element.scrollIntoView()
            }
            else if(link.includes('section5')){
                var element = document.getElementById('Roadmap')
                element.scrollIntoView()
            }
            else if(link.includes('section6')){
                var element = document.getElementById('Team')
                element.scrollIntoView()
            }
        },200)
        
    }

    return(
        <section id='Navigation'>
            <div className='container'>
                <Navbar id="navbarBg" collapseOnSelect expand="lg" variant="dark" fixed="top" className="navbarBg navbar-fixed-top">
                <Container className="logo-className">
                    <Navbar.Brand id='navbar-logo' onClick={()=>scroll('section1')}><img src={logo} alt='banner-logo'/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto links">
                            <Nav.Link className="nav-items text-nowrap" onClick={()=>scroll('section2')}>TOKEN UTILITY</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" onClick={()=>scroll('section3')}>TOKENOMICS</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" onClick={()=>scroll('section4')}>ABOUT</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" onClick={()=>scroll('section5')}>ROADMAP</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" onClick={()=>scroll('section6')}>TEAM</Nav.Link>
                            <Nav.Link className="nav-items text-nowrap" href="#">WHITEPAPER</Nav.Link>
                            <a href='/#/test'  rel="noopener noreferrer" id='tab-mobile-btn'><button>CONTACT US</button></a>
                    </Nav>
                    </Navbar.Collapse>

                    <div className="nav-btn text-nowrap">
                        <a href='/#/test'  rel="noopener noreferrer"><button>CONTACT US</button></a>
                    </div>

                    <Navbar.Brand id='navbar-logo' className='mobile-logo' onClick={()=>scroll('section1')}><img src={logo} alt='banner-logo'/></Navbar.Brand>

                </Container>
                </Navbar>
            </div>
        </section>
    );
}

export default Navigation;