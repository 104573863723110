

import './App.css';
import './Responsive.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from './components/Navigation';
import { HashRouter, Route, Routes } from 'react-router-dom';
import ContactUs from './components/Contactus';
import Home from './components/Home';

function App() {
  return (
    <div className="body">
     
      <HashRouter>
      <Navigation />
        <Routes>
          <Route element={<Home/>} path=''/>
          <Route element={<ContactUs/>} path='/test'/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
